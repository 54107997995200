.App {
  text-align: center;
}

[data-test="table-foot"] {
  display: none;
}
.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.D_BTN{
  padding-left: 1rem;
  cursor: pointer;
  color: red;
}
.D_BTN:hover{
  color: #282c34;
}

.selFill{
  width: 10vw;
}
.tbl{
  height: 375px;

}
table.scrolldown{
  width: 100%; 
  border-spacing: 0; 
}
table.scrolldown thead{
  display: inline-block;
  width: 100%;
  /* border: 2px solid rgb(190, 41, 41); */

}
table.scrolldown thead tr{
  display: flex;
  justify-content: space-between;
  height: 40px;  
  line-height: 30px; 
}
table.scrolldown thead tr th{
  border: none;
}
table.scrolldown tbody{
  display: inline-block;
}  

tr { 
  height: 40px;  
  line-height: 30px; 
}
table.scrolldown tbody {            
  /* Set the height of table body */ 
    width: 100%;
  /* Set vertical scroll */ 
  /* overflow-y: hidden;  */
  /* Hide the horizontal scroll */ 
  /* overflow-x: hidden;   */
} 
table.scrolldown tr td{
  width: 50%;
  
  /* border: 2px solid rgb(190, 41, 41);   */
}
.tbl::-webkit-scrollbar {
  width: 5px;
}
/* .tbl::-webkit-scrollbar-thumb  {
  background: #f1f1f1;
  outline: 1px solid slategrey;
} */


.attribt{
  display: flex;
  align-items: center;
}
input[type="file"]{
  padding:3px 10px;
}

.imgDiv {
  display: flex;
  align-items: center;  /* Align image and buttons vertically centered */
  border: 1px solid #36363634;
  border-radius: 5px;
  width: 650px;
  padding: 5px;
  margin-top: 32px;
}

.imgDiv img {
  max-width: 150px;  /* Set a fixed width for the image */
  height: auto;
  margin-right: 20px;  /* Space between the image and buttons */
}

.button-group {
  display: flex;
  gap: 10px;  /* Space between the buttons */
}

.button-group button {
  padding: 7px 15px;
  outline: none;
  border: 1px solid #36363634;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 1px;
  border-radius: 5px;
  background-color: #fff;
  cursor: pointer;
  transition: background-color 0.3s;
}

.remove-btn {
  background-color: #f44336;
}

.remove-btn:hover {
  background-color: #d32f2f;
  color: #fff;
}

.timestamp-btn {
  background-color: #4CAF50;
}

.timestamp-btn:hover {
  background-color: #388E3C;
  color: #fff;
}

.modal{
  border: 1px solid red;
  width: 18vw;
}

.react-responsive-modal-modal{
  width: 28%;
  border-radius: 5px
}

.AdminModal{
  width: 100%;
  /* gap: 15px; */
  display: flex;
  flex-direction: column;
}
.AdminModal h4{
  text-align: center;
  font-size: 18px;
}
.dlt{
  color: rgba(255, 0, 0, 0.664);
  padding-left: 10px;
  cursor: pointer;
}
.dlt:hover{
 color: red;
}